import { combineReducers } from 'redux';

import user from './user';
import messages from './flash-messages';
import sidebar from './sidebar';

export default combineReducers({
  user,
  messages,
  sidebar,
});
