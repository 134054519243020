import { MESSAGE_NEW, MESSAGE_READ } from 'actions/flash-messages';

function getRest(messages) {
  const [, ...rest] = messages;
  return rest;
}

export default (state = [], action) => {
  switch (action.type) {
    case MESSAGE_NEW:
      return [...state, action.message];

    case MESSAGE_READ:
      return getRest(state);

    default:
      return state;
  }
};
