import logo from 'assets/img/logo.svg';
import loginBackground from 'assets/img/bs-night.jpg';
import blue from '@material-ui/core/colors/blue';

const brand = {
  title: 'MYCredit',
  loginBackground,
  logo,
  color: blue,
};

export default brand;
