// import ApolloClient from 'apollo-boost';
import { createUploadLink } from 'apollo-upload-client';

/*
 *
 * Apollo Boost with uploadLink instead of httpLink
 * https://github.com/apollographql/apollo-client/issues/3161
 *
 * line 98: httpLink swapped with config.uploadLink
 *
 */
import ApolloClient from './apollo-boost-static';

import { host } from 'config';

const link = createUploadLink({
  uri: `${host}/graphql`,
  credentials: 'include',
});

export const client = new ApolloClient({
  uri: `${host}/graphql`,
  credentials: 'include',
  uploadLink: link,
});
