import 'isomorphic-fetch';

import { apiPath } from './path';

const ok = (res) => res.json();
const ko = (res) =>
  res.json().then((error) => {
    throw new Error(error);
  });

export const post = (path, body) =>
  fetch(apiPath(path), {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }).then((res) => (res.ok ? ok(res) : ko(res)));
