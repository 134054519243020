// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../styles/variables.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-web-components-sidebar-___sidebar__sidebar___3xRV9 {\n  height: 100%;\n  background-color: var(--color--primary-500);\n  width: 260px;\n  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),\n    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);\n}\n\n.src-web-components-sidebar-___sidebar__sidebar___3xRV9 > ul {\n  padding-top: 0;\n}\n\n.src-web-components-sidebar-___sidebar__logo___w_-tr {\n  padding: var(--size--3);\n  background-color: var(--color--primary-400);\n}\n\n.src-web-components-sidebar-___sidebar__logo___w_-tr img {\n  width: 100%;\n}\n\n.src-web-components-sidebar-___sidebar__sidebar___3xRV9 .sidebar-text {\n  color: var(--color--neutral-050);\n}\n.src-web-components-sidebar-___sidebar__sidebar___3xRV9 .sidebar-icon {\n  color: rgba(255, 255, 255, 0.54);\n}\n.src-web-components-sidebar-___sidebar__icon-user___3zwnJ {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.src-web-components-sidebar-___sidebar__icon-user___3zwnJ svg {\n  width: var(--size--5);\n  margin-left: var(--size--2);\n  flex-shrink: 0;\n  border: 2px solid var(--color--primary-700);\n}\n.src-web-components-sidebar-___sidebar__icon-user___3zwnJ > span {\n  margin-right: var(--size--1);\n  flex-grow: 1;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n", ""]);
// Exports
exports.locals = {
	"sidebar": "src-web-components-sidebar-___sidebar__sidebar___3xRV9",
	"logo": "src-web-components-sidebar-___sidebar__logo___w_-tr",
	"icon-user": "src-web-components-sidebar-___sidebar__icon-user___3zwnJ"
};
module.exports = exports;
