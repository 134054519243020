export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';

export const openSidebar = () => ({
  type: SIDEBAR_OPEN,
});

export const closeSidebar = () => ({
  type: SIDEBAR_CLOSE,
});
