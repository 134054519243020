import { createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';

import brand from 'config/brand';

export const drawerWidth = 240;

export default createMuiTheme({
  palette: {
    background: {
      default: '#f4f5f7',
    },
    primary: brand.color,
    secondary: amber,
  },
});
