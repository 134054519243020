import { SIDEBAR_OPEN, SIDEBAR_CLOSE } from 'actions/sidebar';

export default (state = { open: false }, action) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return { open: true };

    case SIDEBAR_CLOSE:
      return { open: false };

    default:
      return state;
  }
};
