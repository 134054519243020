import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const Item = ({ Icon, text, to }) => (
  <NavLink to={to}>
    <ListItem button>
      {Icon && (
        <ListItemIcon classes={{ root: 'sidebar-icon' }}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText classes={{ primary: 'sidebar-text' }} primary={text} />
    </ListItem>
  </NavLink>
);

export default Item;
