import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './private-route';
import progress from './progress';

const Search = React.lazy(() => import('containers/search'));
const ProcedureList = React.lazy(() => import('containers/procedure-list'));
const UserProcedureList = React.lazy(() => import('containers/procedure-list/procedure-user-list-container'));
const ProcedureAdd = React.lazy(() => import('containers/procedure-add'));
const ProcedureExport = React.lazy(() => import('containers/procedure-export'));
const ProcedureImport = React.lazy(() => import('containers/procedure-import'));
const ProcedureBase = React.lazy(() => import('containers/procedure-base'));
const LotAdd = React.lazy(() => import('containers/lot-add'));
const LotBase = React.lazy(() => import('containers/lot-base'));
const LotSubmit = React.lazy(() => import('containers/lot-submit'));
const LotProspects = React.lazy(() => import('containers/lot-prospects'));
const LotAgencies = React.lazy(() => import('containers/lot-agencies'));
const LotArchived = React.lazy(() => import('containers/lot-archived'));
const Login = React.lazy(() => import('containers/login'));
const Account = React.lazy(() => import('containers/account'));
const UserList = React.lazy(() => import('containers/user-list'));
const UserAdd = React.lazy(() => import('containers/user-add'));
const UserEdit = React.lazy(() => import('containers/user-edit'));
const RelatedLists = React.lazy(() => import('containers/related-lists'));
// const FlowList = React.lazy(() => import('containers/flow-list'));
// const Week = React.lazy(() => import('containers/week'));
// const WeekTotal = React.lazy(() => import('containers/week-total'));
const Alerts = React.lazy(() => import('containers/alerts'));
const Activities = React.lazy(() => import('containers/activities'));
const Agencies = React.lazy(() => import('containers/agency-list'));
const AgencyAdd = React.lazy(() => import('containers/agency-add'));
const AgencyEdit = React.lazy(() => import('containers/agency-edit'));

export default ({ user }) => (
  <div>
    <Suspense fallback={progress}>
      <Route path="/login" component={Login} />
      <Route path="/agenzie" component={LotSubmit} />
      <Route path="/desktop" component={LotSubmit} />
      <Route path="/accessi" component={LotSubmit} />
      <PrivateRoute user={user} exact path="/" component={Search} />
      <PrivateRoute
        user={user}
        exact
        path="/procedures"
        component={ProcedureList}
      />
      <PrivateRoute
        user={user}
        path="/user/:userId/procedures"
        component={UserProcedureList}
      />
      <PrivateRoute
        user={user}
        exact
        path="/procedure-add"
        component={ProcedureAdd}
      />
      <PrivateRoute
        user={user}
        path="/procedure/:id"
        component={ProcedureBase}
      />
      <PrivateRoute
        exact
        user={user}
        path="/procedure-export"
        component={ProcedureExport}
      />
      <PrivateRoute
        exact
        user={user}
        path="/procedure-import"
        component={ProcedureImport}
      />
      <PrivateRoute
        exact
        user={user}
        path="/procedure-export/:userId"
        component={ProcedureExport}
      />
      <PrivateRoute
        user={user}
        path="/lot-add/:procedureId/:procedureCustomId"
        component={LotAdd}
      />
      <PrivateRoute user={user} path="/lot/:id" component={LotBase} />
      <PrivateRoute
        user={user}
        path="/lot-prospects"
        component={LotProspects}
      />
      <PrivateRoute
        user={user}
        path="/lot-agencies"
        component={LotAgencies}
        exact
      />
      <PrivateRoute
        user={user}
        path="/lot-archived"
        component={LotArchived}
        exact
      />
      <PrivateRoute
        user={user}
        path="/lot-agencies/:id"
        component={LotAgencies}
        exact
      />
      <PrivateRoute user={user} path="/account" component={Account} />
      <PrivateRoute user={user} path="/users" component={UserList} />
      <PrivateRoute user={user} path="/user-add" component={UserAdd} />
      <PrivateRoute user={user} path="/user-edit/:id" component={UserEdit} />
      <PrivateRoute user={user} path="/related" component={RelatedLists} />
      {/*
      <PrivateRoute
        user={user}
        path="/flows/:procedureId/:procedureCustomId"
        component={FlowList}
      />*/}
      {/*
      <PrivateRoute user={user} path="/week/:id" component={Week} />
      <PrivateRoute user={user} path="/week-total" component={WeekTotal} />
      */}
      <PrivateRoute user={user} path="/alerts" component={Alerts} />
      <PrivateRoute user={user} path="/activities" component={Activities} />
      <PrivateRoute
        user={user}
        path="/user/:user/activities"
        component={Activities}
      />
      <PrivateRoute user={user} path="/agencies" component={Agencies} />
      <PrivateRoute user={user} path="/agency-add" component={AgencyAdd} />
      <PrivateRoute
        user={user}
        path="/agency-edit/:id"
        component={AgencyEdit}
      />
    </Suspense>
  </div>
);
