import React from 'react';
import { connect } from 'react-redux';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListAltIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import UserIcon from '@material-ui/icons/AccountBox';
import AgencyIcon from '@material-ui/icons/StoreMallDirectory';
import WeekIcon from '@material-ui/icons/CalendarToday';
import AlertsIcon from '@material-ui/icons/Send';
import ActivitiesIcon from '@material-ui/icons/Computer';
import ProspectsIcon from '@material-ui/icons/Domain';
import { useTranslation } from 'react-i18next';

import { closeSidebar, openSidebar } from 'actions/sidebar';
import { isSuper, admin, company } from 'utils/role';
import brand from 'config/brand';
import { getFlag } from 'locale/flags';

import Item from './item';

import './sidebar.css';

const UserLabel = ({ user, lang }) => (
  <span styleName="icon-user">
    <span>{user && user.username}</span>
    {getFlag(lang)}
  </span>
);

const Sidebar = ({ user, open, closeSidebar }) => {
  const { t, i18n } = useTranslation();
  return (
    <SwipeableDrawer open={open} onClose={closeSidebar} onOpen={openSidebar}>
      <div styleName="sidebar" role="button" onClick={closeSidebar}>
        <List>
          {brand.logo && (
            <div styleName="logo">
              <img src={brand.logo} alt={brand.title} />
            </div>
          )}
          <Item Icon={SearchIcon} text={t('Search')} to="/" />
          {company(user) && (
            <Item
              Icon={AddIcon}
              text={t('New procedure')}
              to="/procedure-add"
            />
          )}
          <Item
            Icon={ListAltIcon}
            text={t('Procedure list')}
            to="/procedures"
          />
          {company(user) && (
            <Item Icon={AgencyIcon} text={t('Brokers')} to="/agencies" />
          )}
          {company(user) && (
            <Item
              Icon={ProspectsIcon}
              text={t('External reports')}
              to="/lot-prospects"
            />
          )}
          {/*<Divider />
          { company(user) && (
            <Item
              Icon={WeekIcon}
              text={t('Working Tables (Tot.)')}
              to="/week-total"
            />
          )}
          {company(user) && (
            <Item
              Icon={WeekIcon}
              text={t('Working Tables (Contr.)')}
              to={user && `/week/${user._id}`}
            />
          )*/}
          <Divider />
          {isSuper(user) && (
            <Item Icon={PeopleIcon} text={t('Users')} to="/users" />
          )}
          {isSuper(user) && (
            <Item Icon={SettingsIcon} text={t('Management')} to="/related" />
          )}
          {isSuper(user) && (
            <Item Icon={AlertsIcon} text={t('Alerts')} to="/alerts" />
          )}
          {admin(user) && (
            <Item
              Icon={ActivitiesIcon}
              text={t('Activities')}
              to="/activities"
            />
          )}
          <Divider />
          <Item
            Icon={UserIcon}
            text={<UserLabel user={user} lang={i18n.language} />}
            to="/account"
          />
          <Divider />
          <Item text={`v. ${APP_VERSION}`} to="" />
        </List>
      </div>
    </SwipeableDrawer>
  );
};

const mapStateToProps = (state) => ({
  open: state.sidebar.open,
  user: state.user,
});

const mapDispatchToProps = { closeSidebar, openSidebar };

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
