const getStoredProp = (prop) => {
  try {
    return global.localStorage.getItem(prop) || null;
  } catch (e) {
    return null;
  }
};
const storeProp = (prop, value) => {
  try {
    global.localStorage.setItem(prop, value);
    return true;
  } catch (e) {
    console.log('local storage not available');
    return false;
  }
};

export const getLanguage = () => getStoredProp('restate_lang') || 'it';
export const saveLanguage = (value) => storeProp('restate_lang', value);
export const getSoundOn = () =>
  getStoredProp('restate_sound_on') !== 'inactive';
export const saveSoundOn = (value) =>
  storeProp('restate_sound_on', value ? 'active' : 'inactive');
