import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { readMessage } from 'actions/flash-messages';

class FlashMessage extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }
  getMessage() {
    return this.props.messages.length > 0 ? this.props.messages[0] : '';
  }

  handleClose() {
    this.props.readMessage();
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.props.messages.length > 0}
        message={this.getMessage()}
        autoHideDuration={2000}
        onClose={this.handleClose}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

const mapStateToProps = ({ messages }) => ({ messages });
const mapDispatchToProps = { readMessage };

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);
