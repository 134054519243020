import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLanguage } from 'utils/storage';
import it from './locales/it.json';
import en from './locales/en.json';

const resources = {
  it: {
    translation: it,
  },
  en: {
    translation: en,
  },
};

export const languages = ['it', 'en'];
const lng = getLanguage();

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  whitelist: languages,
  lng,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
