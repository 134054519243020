import en from './en';
import it from './it';
import empty from './empty';

const flags = {
  en,
  it,
};

export const getFlag = (lang) => flags[lang] || empty;

export default flags;
