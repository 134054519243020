import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Sidebar from 'components/sidebar';
import FlashMessage from 'components/flash-message';

import Routes from './routes';

import './main.css';

const Main = ({ user }) => (
  <BrowserRouter>
    <div styleName="outer">
      {user && <Sidebar user={user} />}
      <div styleName="main">
        <div styleName="main-route">
          <Routes user={user} />
        </div>
      </div>
      <FlashMessage />
    </div>
  </BrowserRouter>
);

export default Main;
